import Vue from 'vue'
import App from '@/App.vue'
import router from '@/plugins/router'
import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false

var VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo)

new Vue({
	router,
	vuetify,
	data: {
		publicPath: process.env.BASE_URL,
		thumbImage: process.env.VUE_APP_THUMBIMAGE + '?t=',
	},
	render: h => h(App),
}).$mount('#app')
