import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routesHome = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "HomeIndex" */ '@/components/home/index'),
	},
	{
		path: '/blog',
		component: () => import(/* webpackChunkName: "HomeBlog" */ '@/components/home/blog')
	},
	{
		path: '/blog/1/:BlogTitle',
		component: () => import(/* webpackChunkName: "HomeBlog" */ '@/components/home/blog1')
	},
	{
		path: '/blog/2/:BlogTitle',
		component: () => import(/* webpackChunkName: "HomeBlog" */ '@/components/home/blog2')
	},
	{
		path: '/wholesale',
		component: () => import(/* webpackChunkName: "HomeWholesale" */ '@/components/home/wholesale')
	}
]

const routesUser = []

const routesAdmin = []

const routesEtc = [{
	path: '*',
	component: () => import( /* webpackChunkName: "NotFound" */ '@/components/notfound')
}]

const routes = [].concat(routesHome, routesUser, routesAdmin, routesEtc)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: function (to) {
		if (to.hash) {
			return {selector: to.hash}
		} else {
			return { x: 0, y: 0 }
		}
	}
})

export default router
